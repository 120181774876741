import { createAction } from 'typesafe-actions';

export const selectAmount = createAction('SELECT_AMOUNT', (amount: number) => ({
  amount,
}))();

export const startPayment = createAction('START_PAYMENT')();

export const addTransactionId = createAction(
  'ADD_TRANSACTION_ID',
  (transactionId: string) => ({
    transactionId,
  }),
)();
